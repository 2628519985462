<template>
  <div>
    <FilterOverview
      :title="$t('COMMON.CARDS.OVERVIEW')"
      @reload="onReload"
      :isInitiallyLoading="isInitiallyLoading"
      :showFilterButton="true"
    >
      <template #filter> <ReisenFilter></ReisenFilter></template>
      <template #toolbar-right>
        <b-button
          variant="primary"
          size="sm"
          @click="onAddReiseClick()"
          v-allowedRoles="[Role.ADMIN, Role.PM]"
        >
          <b-icon icon="plus" />Reise anlegen</b-button
        ></template
      >
      <template #table="{ tableHeight, heightComponentInlineStyle }"
        ><ReiseTable
          :reisen="reisen"
          :tableHeight="tableHeight"
          :heightComponentInlineStyle="heightComponentInlineStyle"
        ></ReiseTable
      ></template>
    </FilterOverview>
    <ReisenModalEdit />
  </div>
</template>

<script>
import { SET_IS_ADDING_REISE, GET_REISEN } from '@/core/produkte/reisen/stores/reisen.module';
import ReiseTable from '@/components/produkte/reisen/reisen-table';
import ReisenModalEdit from '@/components/produkte/reisen/reisen-modal-edit.vue';
import ReisenFilter from '@/components/produkte/reisen/reisen-filter.vue';
import FilterOverview from '@/components/common/filter-overview.vue';
import { mapState } from 'vuex';

export default {
  name: 'ReisenOverviewComponent',
  components: {
    ReiseTable,
    ReisenModalEdit,
    ReisenFilter,
    FilterOverview,
  },
  computed: {
    ...mapState({
      isInitiallyLoading: state => state.reisen.isInitiallyLoading,
      reisen: state => state.reisen.reisen,
    }),
  },
  methods: {
    onReload() {
      this.$store.dispatch(GET_REISEN, { isOverview: true });
    },
    onAddReiseClick() {
      this.$store.commit(SET_IS_ADDING_REISE, true);
    },
  },
  mounted() {
    this.$store.dispatch(GET_REISEN, { isOverview: true });
  },
};
</script>
