<template>
  <div class="d-flex flex-column flex-grow-1">
    <FilterRegionCountry
      placeholder="Status"
      class="flex-column mt-4"
      @inputRegion="setRegionFilter"
      @inputCountry="setCountryFilter"
      :countriesGroupedByRegions="countriesGroupedByRegions"
      :valueRegion="regionFilter"
      :valueCountry="countryFilter"
    />
    <SearchReiseMultiple
      class="mt-4"
      @reiseSelected="setReisenKuerzelFilter"
      :options="reisenkuerzelFilterOptions"
      :value="reisenkuerzelFilter"
      :placeholder="$t('REISEN.COMMON.REISE')"
    />
    <FilterMissingData
      class="mt-4"
      @input="setIsMissingDataFilter"
      :value="missingDataFilter"
      :placeholder="$t('REISEN.COMMON.REISE')"
    />
    <b-form-checkbox
      class="mt-4"
      @input="setIsWithoutKonfigurationFilter"
      :checked="isWithoutKonfigurationFilter"
      switch
      size="md"
    >
      Reisen ohne Konfigurationen
    </b-form-checkbox>
    <DateRangePicker
      class="mt-4"
      :placeholder="$t('REISEN.COMMON.STARTDATE')"
      :value="startDateFilter"
      @input="setStartDateFilter"
    />
    <div class="w-100 mt-4 flex-grow-1 flex-column">
      <b-button
        :disabled="isDifferentFilterApplied || isInitiallyLoading"
        lg
        block
        @click="onClickSaveFilter($event)"
        variant="primary"
        >{{ $t('COMMON.FILTERANWENDEN') }}</b-button
      >
      <b-button
        block
        variant="danger"
        @click="onClickResetFilter($event)"
        v-show="isAnyFilterApplied"
        v-t="'REISEN.COMMON.RESET'"
      >
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

import {
  SAVED_FILTER,
  RESET_FILTER,
  SET_IS_CURRENT_FILTER_APPLIED,
  SET_REISEN_KUERZEL_FILTER,
  GET_REISEN_KUERZEL_FILTER_OPTIONS,
  GET_ALL_COUNTRIES_GROUPED_BY_REGIONS,
  SET_REGION_FILTER,
  SET_COUNTRY_FILTER,
  SET_MISSING_DATA_FILTER,
  SET_START_DATE_FILTER,
  SET_IS_WITHOUT_KONFIGURATION_FILTER,
} from '@/core/produkte/reisen/stores/reisen.module';

import SearchReiseMultiple from '@/components/produkte/reisen/search-reise-multiple.vue';
import FilterRegionCountry from '@/components/produkte/reisetermine/filter-region-country.vue';
import FilterMissingData from '@/components/produkte/reisen/filter-missing-data.vue';
import DateRangePicker from '@/components/common/date-range-picker.vue';

export default {
  components: {
    SearchReiseMultiple,
    FilterRegionCountry,
    FilterMissingData,
    DateRangePicker,
  },
  computed: {
    ...mapState({
      isAnyFilterApplied: state => state.reisen.isAnyFilterApplied,
      isInitiallyLoading: state => state.reisen.isInitiallyLoading,

      countriesGroupedByRegions: state => state.reisen.countriesGroupedByRegions,
      isCurrentFilterApplied: state => state.reisen.isCurrentFilterApplied,
      appliedReisen: state => state.reisen.appliedReisenFilter,
      reisenkuerzelFilter: state => state.reisen.filter.reisenkuerzelFilter,
      reisenkuerzelFilterOptions: state => state.reisen.reisenkuerzelFilterOptions,
      regionFilter: state => state.reisen.filter.regionFilter,
      countryFilter: state => state.reisen.filter.countryFilter,
      missingDataFilter: state => state.reisen.filter.missingDataFilter,
      startDateFilter: state => state.reisen.filter.startDateFilter,
      isWithoutKonfigurationFilter: state => state.reisen.filter.isWithoutKonfigurationFilter,
    }),
    ...mapGetters(['isDifferentFilterApplied']),
  },
  mounted() {
    this.$store.dispatch(GET_REISEN_KUERZEL_FILTER_OPTIONS);

    if (!this.countriesGroupedByRegions) {
      this.$store.dispatch(GET_ALL_COUNTRIES_GROUPED_BY_REGIONS);
    }
  },
  methods: {
    onClickSaveFilter($event) {
      this.$store.dispatch(SAVED_FILTER);
    },
    onClickResetFilter($event) {
      this.$store.dispatch(RESET_FILTER);
    },
    setReisenKuerzelFilter(filter) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_REISEN_KUERZEL_FILTER, filter);
    },
    setRegionFilter(region) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_REGION_FILTER, region);
      // Reisekuerzel filter is based on region and country selection so we have to reload it
      // this.$store.commit(REFRESH_REISEN_KUERZEL_FILTER_OPTIONS);
    },
    setCountryFilter(country) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_COUNTRY_FILTER, country);
      // Reisekuerzel filter is based on region and country selection so we have to reload it
      // this.$store.dispatch(REFRESH_REISEN_KUERZEL_OPTION);
    },
    setIsMissingDataFilter(missingDataFilter) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_MISSING_DATA_FILTER, missingDataFilter);
    },
    setStartDateFilter(start, end) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_START_DATE_FILTER, {
        start: start || null,
        end: end || null,
      });
    },
    setIsWithoutKonfigurationFilter(isWithoutKonfigurationFilter) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_IS_WITHOUT_KONFIGURATION_FILTER, isWithoutKonfigurationFilter);
    },
  },
};
</script>
