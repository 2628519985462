<template>
  <div class="rounded border p-2">
    <b-form-radio-group
      class="w-100"
      v-model="selected"
      :options="options"
      buttons
      block
      stacked
      size="sm"
      value-field="filter"
      button-variant="secondary"
      @input="$emit('input', $event)"
    ></b-form-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, default: null },
  },
  data() {
    return {
      selected: null,
      options: [
        {
          text: this.$t('COMMON.ALLEREISEN'),
          filter: null,
        },
        {
          text: this.$t('COMMON.MITFEHLENDENDATEN'),
          filter: {
            reisetermine: {
              any: {
                and: [
                  { isDeleted: false },
                  {
                    or: [
                      { metadata: { reisedauer: null } },
                      { metadata: { kalkPax: null } },
                      { metadata: { overnightOutbound: null } },
                      { metadata: { overnightInbound: null } },
                      { metadata: { hotelstartVorLandung: null } },
                      { metadata: { rueckflugNachHotelende: null } },
                      { metadata: { ziellandZielflughaefen: { $count: { eq: 0 } } } },
                      { metadata: { ziellandAbflughaefen: { $count: { eq: 0 } } } },
                      { metadata: { transferszenario: null } },
                    ],
                  },
                  { reiseterminState: { baseState: { ne: 'Durchgefuehrt' } } },
                  'reisetermine/startdatum gt now()',
                ],
              },
            },
          },
        },
        {
          text: this.$t('COMMON.OHNEFEHLENDEDATEN'),
          filter: {
            and: [
              { reisetermine: { $count: { gt: 0 } } },
              {
                reisetermine: {
                  all: {
                    and: [
                      { isDeleted: false },
                      { metadata: { reisedauer: { ne: null } } },
                      { metadata: { kalkPax: { ne: null } } },
                      { metadata: { overnightOutbound: { ne: null } } },
                      { metadata: { overnightInbound: { ne: null } } },
                      { metadata: { hotelstartVorLandung: { ne: null } } },
                      { metadata: { rueckflugNachHotelende: { ne: null } } },
                      { metadata: { ziellandZielflughaefen: { $count: { ge: 1 } } } },
                      { metadata: { ziellandAbflughaefen: { $count: { ge: 1 } } } },
                      { reise: { konfigurationen: { $count: { gt: 0 } } } },
                      { reiseterminState: { baseState: { ne: 'Durchgefuehrt' } } },
                      'reisetermine/startdatum gt now()',
                    ],
                  },
                },
              },
            ],
          },
        },
      ],
    };
  },
};
</script>
