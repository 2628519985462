<template>
  <div class="card card-custom card-stretch" :style="heightComponentInlineStyle">
    <div class="card-body d-flex flex-column justify-content-between">
      <b-table
        :items="reisen"
        :fields="fields"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        :sticky-header="tableHeight + 'px'"
        :empty-filtered-text="$t('COMMON.EMPTYFILTEREDTEXT', { name: 'Reisen' })"
        :empty-text="$t('COMMON.EMPTYTEXT', { name: 'Reisen' })"
        show-empty
        responsive="sm"
        no-local-sorting
        no-sort-reset
        select-mode="multi"
        thead-class="text-nowrap"
        @sort-changed="onSortChanged"
      >
        <template v-slot:cell(region)="row">
          <b-badge variant="primary">{{ $t('REISEN.REGIONS.' + row.item.region) }}</b-badge>
        </template>
        <template v-slot:cell(country)="row">
          <b-badge variant="primary">{{ $t('REISEN.COUNTRIES.' + row.item.country) }}</b-badge>
        </template>
        <template v-slot:cell(actions)="row">
          <ActionButton
            :link="{ name: 'reisen-edit-view', params: { reisekuerzel: row.item.reisekuerzel } }"
            :title="$t('REISEN.COMMON.DETAILS')"
            extraClass="btn-text"
            v-allowedRoles="[Role.ADMIN, Role.PM, Role.YIELD, Role.DOM]"
          >
            Reise bearbeiten
          </ActionButton>
          <ActionButton
            :link="{ name: 'reisen-reisestammdaten-view', params: { reisekuerzel: row.item.reisekuerzel } }"
            :title="$t('REISEN.COMMON.DETAILS')"
            extraClass="btn-text"
            v-allowedRoles="[Role.ADMIN, Role.PM, Role.YIELD, Role.DOM]"
          >
            Reisestammdaten bearbeiten
          </ActionButton>
        </template>

        <template slot="row-details" slot-scope="row" v-if="row.item._showDetails">
          <ReiseTableDetails :item="row.item"></ReiseTableDetails>
        </template>
      </b-table>

      <SKRPagination
        v-model="currentPage"
        :isLoading="isBusy"
        :totalRows="count"
        :currentRows="reisen?.length || 0"
        ariaControls="reiseTable"
        @pageChange="onPageChange"
      ></SKRPagination>
    </div>
  </div>
</template>

<style lang="scss">
.table-dropdown > .btn {
  padding: 0;
}
.columnWidth {
  width: 10px;
}
</style>

<script>
import {
  GET_REISEN,
  REISEN_SORT_CHANGED,
  REISEN_PAGE_CHANGED,
} from '@/core/produkte/reisen/stores/reisen.module';
import { mapState } from 'vuex';

import ReiseTableDetails from '@/components/produkte/reisen/reisen-table-details';
import SKRPagination from '@/components/common/skr-pagination.vue';
import ActionButton from '@/components/common/action-button.vue';

import configPirority from '@/core/produkte/reisen/priority.config.json';

export default {
  name: 'ReiseTable',
  props: {
    tableHeight: {
      type: Number,
      required: false,
    },
    reisen: {
      type: Array,
    },
    heightComponentInlineStyle: {
      type: String,
      required: false,
    },
  },
  components: {
    ReiseTableDetails,
    SKRPagination,
    ActionButton,
  },
  data() {
    return {
      currentPage: 1,
      sortBy: 'region',
      sortDesc: false,
      fields: [
        { key: 'selected', label: '', tdClass: 'columnWidth' },
        {
          key: 'reisekuerzel',
          label: this.$t('REISEN.COMMON.REISE'),
          sortable: true,
        },
        { key: 'region', sortable: true },
        {
          key: 'country',
          label: this.$t('REISEN.COMMON.COUNTRY'),
          sortable: true,
        },
        {
          key: 'prioritaet',
          label: this.$t('COMMON.PRIORITAET'),
          sortable: true,
          formatter: value => {
            return configPirority[value]?.value || '-';
          },
        },
        {
          key: 'reisetermine@odata.count',
          label: '# ' + this.$t('REISEN.COMMON.REISETERMINE'),
          sortable: true,
        },
        { key: 'actions', label: this.$t('REISEN.COMMON.ACTIONS') },
      ],
      selected: [],
    };
  },
  computed: {
    ...mapState({
      isBusy: state => state.reisen.isBusy,
      isInitiallyLoading: state => state.reisen.isInitiallyLoading,
      count: state => state.reisen.count,
    }),
  },

  methods: {
    clickSelectDiv(event, item) {
      const idx = this.reisen.indexOf(item);
      this.reisen[idx].selected = !this.reisen[idx].selected;
      this.$forceUpdate();

      event.stopPropagation();
      event.preventDefault();
    },
    onPageChange(ctx) {
      this.$store.dispatch(REISEN_PAGE_CHANGED, { pageContext: ctx });
    },
    onSortChanged(ctx) {
      if (ctx.sortBy === 'reisetermine@odata.count') {
        ctx.sortBy = 'reisetermine/$count';
      }
      this.$store.dispatch(REISEN_SORT_CHANGED, { sortContext: ctx });
    },
  },
};
</script>
