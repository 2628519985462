<template>
  <b-modal
    ref="modal-reise-edit"
    auto-focus-button="ok"
    id="modal-reise-edit"
    :title="isAddingReise ? $t('REISEN.MODALS.ADDTITLE') : $t('REISEN.MODALS.EDITTITLE')"
    @hidden="onHiddenModal"
    @ok="handleOk"
  >
    <b-form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label-for="reisekuerzel" :invalid-feedback="validateState('reisekuerzel', true)">
        <slot name="label">
          <label v-t="'REISEN.COMMON.REISEKUERZEL'"></label>&nbsp;
          <b-icon-question-circle-fill
            v-b-popover.hover.top="$t('REISEN.POPOVERTEXT.Widget1')"
          ></b-icon-question-circle-fill>
        </slot>
        <b-form-input
          id="reisekuerzel"
          v-model="$v.form.reisekuerzel.$model"
          :formatter="v => v.toUpperCase()"
          :state="validateState('reisekuerzel')"
          type="text"
          trim
          :disabled="!isAddingReise"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group label="Reisetitel" label-for="titel">
        <b-form-input id="titel" type="text" v-model="$v.form.titel.$model" :state="validateState('titel')">
        </b-form-input>
      </b-form-group>
      <b-form-group
        :invalid-feedback="validateState('country', true)"
        :label="$t('REISEN.LANDEINGEBEN')"
        label-for="country"
      >
        <b-form-select
          id="country"
          v-model="$v.form.country.$model"
          :state="validateState('country')"
          :options="countriesOptions"
          text-field="text"
          value-field="value"
        >
        </b-form-select>
      </b-form-group>

      <b-form-group
        :invalid-feedback="validateState('region', true)"
        :label="$t('REISEN.COMMON.REGION')"
        label-for="region"
      >
        <b-form-select
          id="region"
          v-model="$v.form.region.$model"
          :state="validateState('region')"
          :options="regionOptions"
          text-field="text"
          value-field="value"
        >
        </b-form-select>
      </b-form-group>

      <b-form-group
        :invalid-feedback="validateState('prioritaet', true)"
        :label="$t('REISEN.PRIORITAETEINGEBEN')"
        label-for="prioritaet"
      >
        <b-form-select
          id="prioritaet"
          v-model="$v.form.prioritaet.$model"
          :state="validateState('prioritaet')"
          :options="prioritaetOptions"
          text-field="value"
        >
        </b-form-select>
      </b-form-group>
      <b-form-group
        :invalid-feedback="validateState('regionsmanager', true)"
        :label="'Regionsmanager'"
        label-for="regionsmanager"
      >
        <b-form-select
          id="regionsmanager"
          v-model="$v.form.regionsmanager.$model"
          :state="validateState('regionsmanager')"
          :options="regionsmanagerOptions"
        >
        </b-form-select>
      </b-form-group>
      <b-form-group style="margin-bottom: 16px">
        <b-form-checkbox
          switch
          id="automatischeAufgabeMtzErreicht"
          v-model="$v.form.automatischeAufgabeMtzErreicht.$model"
        >
          Automatische Aufgabe wird bei erreichter MTZ erstellt
        </b-form-checkbox>
      </b-form-group>
    </b-form>
    <LoadSpinnerWidget v-if="isLoading" :dataAvailable="true" :isLoading="isLoading"></LoadSpinnerWidget>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { UPDATE_REISE, SET_IS_ADDING_REISE, ADD_REISE } from '@/core/produkte/reisen/stores/reisen.module.js';
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';

import configCountries from '@/core/produkte/reisen/countries.config.json';
import configRegions from '@/core/produkte/reisen/regions.config.json';
import configPirority from '@/core/produkte/reisen/priority.config.json';

export default {
  mixins: [validationMixin],
  name: 'ReisenEditComponent',
  props: {
    reise: Object,
  },
  components: { LoadSpinnerWidget },
  data() {
    return {
      isLoading: false,
      form: {
        id: null,
        reisekuerzel: null,
        country: null,
        region: null,
        prioritaet: null,
        titel: null,
        regionsmanager: null,
        automatischeAufgabeMtzErreicht: false,
      },
      error: {},
    };
  },
  watch: {
    editingReise(reiseObject) {
      if (reiseObject) {
        this.initModal(reiseObject);
        this.$bvModal.show('modal-reise-edit');
      }
    },
    isAddingReise(isAddingReise) {
      if (isAddingReise) {
        this.initModal();
        this.$bvModal.show('modal-reise-edit');
      }
    },
  },
  computed: {
    ...mapState({ users: state => state.users['users'] }),
    regionsmanagerOptions() {
      return this.users
        .filter(user => user.department && user.department === 'Flug Yield Management')
        .map(user => ({ value: user.id, text: user.givenName + ' ' + user.surname }));
    },
    regionOptions() {
      return Object.entries(configRegions)
        .reduce((acc, cur) => {
          acc.push({ value: cur[1].value, text: this.$t('REISEN.REGIONS.' + cur[1].value) });
          return acc;
        }, [])
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    countriesOptions() {
      return Object.entries(configCountries)
        .reduce((acc, cur) => {
          acc.push({ value: cur[1].value, text: this.$t('REISEN.COUNTRIES.' + cur[1].value) });
          return acc;
        }, [])
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    prioritaetOptions() {
      return configPirority || [];
    },
    ...mapState({
      editingReise: state => state.reisen.editingReise,
      isAddingReise: state => state.reisen.isAddingReise,
    }),
  },
  validations: {
    form: {
      reisekuerzel: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
      country: {
        required,
      },
      region: {
        required,
      },

      prioritaet: {
        required,
      },
      titel: { required },
      regionsmanager: { required },
      automatischeAufgabeMtzErreicht: { required },
    },
  },
  methods: {
    onHiddenModal() {
      if (!this.editingReise) {
        this.$store.commit(SET_IS_ADDING_REISE, false);
      }
    },
    initModal(reise = null) {
      this.form = {
        id: reise?.id || '',
        reisekuerzel: reise?.reisekuerzel || '',
        country: reise?.country || '',
        region: (reise && reise.region && this.$t('REISEN.REGIONS.' + reise?.region)) || '',
        prioritaet: this.prioritaetOptions[reise?.prioritaet]?.value || '',
        regionsmanager: reise?.regionsmanager || '',
        automatischeAufgabeMtzErreicht: reise?.automatischeAufgabeMtzErreicht || false,
      };
    },
    validateState(name, mode = false) {
      if (!mode) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
      if (this.$v.form[name].required != null && !this.$v.form[name].required) {
        return this.$t('FORMS.REQUIREDFIELD');
      }

      if (this.$v.form[name].alpha != null && !this.$v.form[name].alpha) {
        return this.$t('FORMS.BUCHSTABEN');
      }

      if (this.$v.form[name].minLength != null && !this.$v.form[name].minLength) {
        return this.$t('FORMS.BETWEENCHARACTERS', { length: this.$v.form[name].$params.minLength.min });
      }

      if (this.$v.form[name].maxLength != null && !this.$v.form[name].maxLength) {
        return this.$t('FORMS.BETWEENCHARACTERS', { length: this.$v.form[name].$params.minLength.min });
      }

      if (this.$v.form[name].between != null && !this.$v.form[name].between) {
        return this.$t('FORMS.MAXIMUMCHARACTERS', {
          min: this.$v.form[name].$params.between.min,
          max: this.$v.form[name].$params.between.max,
        });
      }
    },
    resetModal() {
      this.form = {
        reisekuerzel: null,
        country: null,
        region: null,
        prioritaet: null,
        titel: null,
        regionsmanager: null,
        automatischeAufgabeMtzErreicht: false,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const savedForm = {
        ...this.form,
        prioritaet: Object.entries(this.prioritaetOptions).find(
          priority => priority[1].value === this.form.prioritaet
        )[0],
      };

      this.isLoading = true;
      const reiseStoreAction = !this.editingReise && this.isAddingReise ? ADD_REISE : UPDATE_REISE;
      this.$store
        .dispatch(reiseStoreAction, { reise: savedForm })
        .then(response => {
          this.$bvModal.hide('modal-reise-edit');

          this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
        })
        .catch(error => {
          console.error(error);
          this.toast('Fehler beim Speichern!');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
